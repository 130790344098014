0<template>
  <MainLayout>
    <template v-slot:default>
      <TableFilters>
        <template v-slot:filter-title>
          <span v-if="sourceObject.count">
            <span class="title-filter">Всего:</span>{{ sourceObject.count }}
            <!--            <span>платежей</span>-->
          </span>
        </template>
        {{ sourceObject }}
      </TableFilters>

      <TableLayout
        :loading="isDateLoaded"
        :table-data="sourceObject.results"
        :header="header"
        :source-object="sourceObject"
        disable-router
      />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsPayment from "../../helpers/tableCols/payment";
export default {
  name: "PaymentsPage",
  data() {
    return {
      downloadPopup: false,
      downloadDialog: false,
      loadingBtn: false,
      queries: query.payments
    };
  },
  components: { TableFilters, TableLayout, MainLayout },
  computed: {
    header() {
      return tableColsPayment.filter(el =>
        el.userRole.includes(this.$store.getters.getCurrentRole)
      );
    },

    sourceObject() {
      return this.$store.getters.getPagePaymentsList;
    },
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    urlQuery() {
      return {
        query: this.$route.query
      };
    }
  },
  methods: {},
  created() {
    this.$store.dispatch("clearSortQuery");

    this.$store.commit("setSearchQuery", {
      query: {
        ...this.urlQuery.query
        // type: this.type
      }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchPageIdPaymentsList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        query: {
          ...to.query
        }
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchPageIdPaymentsList").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearPaymentsPage").then(() => next());
  }
};
</script>

<style scoped></style>
